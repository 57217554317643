import { createContext, useState, useContext, useCallback, ReactNode, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { IconNames } from "@blueprintjs/icons";
import {
  Customer,
  EnabledFeatures,
  UserDetails,
  AwsConfig,
  Abilities,
  PageAccess,
  AuthenticationError
} from "../types/user";
import { authenticateWithPassword } from "../utils/auth/passwordAuth";
import { authenticateWithGoogle } from "../utils/auth/googleAuth";
import { refreshJwt } from "../utils/auth/refreshJwt";
import {
  getAuthMechanism,
  getCustomerId,
  getGoogleSsoAction,
  removeAuthMechanism,
  removeGoogleSsoAction
} from "../utils/localStorage/customer";
import { insertCustomerId, insertUserName } from "../utils/queryParams";
import { LoginWithGoogleAuth } from "../apis/googleSsoApi";
import { ShowToaster } from "../views/common/Toaster";
import { formattedDate } from "./utils/dateHelpers";
import { db } from "./utils/db";
import {
  logout,
  fetchCustomerLoginAttribs,
  fetchEnabledFeatures,
  fetchUserDetails,
  fetchGenerationPresets,
  fetchEncoders,
  fetchRerankers,
  fetchAwsMpDetails,
  fetchLlms
} from "./user/commonAuth";
import { Encoder } from "../generated_protos/admin/admin_encoder_pb";
import { Reranker } from "../generated_protos/admin/admin_reranker_pb";
import { useAnalyticsContext } from "./AnalyticsContext";
import { ReadAWSMPDetailsResponse } from "../generated_protos/admin/admin_account_pb";
import { getAndRemoveAwsSubscriptionToken } from "../utils/localStorage/awsMarketplace";
import { analytics } from "./utils/analytics";
import { UserTrackingData } from "./utils/snowTypes";
import { AdminService, PublicAdminService } from "../backendConfig";
import { AUTH_PROVIDER } from "../backendConfig";
import { ApiV2, GenerationPreset, Llm } from "../apis/apiV2Client";
import { extractLlms } from "./utils/extractLlms";
import { datadogRum } from "@datadog/browser-rum";
import { OptionalFields } from "../utils/types";

let anonymousId: string;
export const setAnonymousId = (id: string) => {
  if (!anonymousId) anonymousId = id;
};

const isAllRequiredStateDefined = (requiredState: RequiredState) => {
  return (Object.keys(requiredState) as Array<keyof typeof requiredState>).every((key) => {
    // We can ignore awsMpDetails if the user isn't an AWS MP customer.
    if (key === "awsMpDetails") {
      return requiredState.isAwsMpCustomer ? requiredState[key] !== undefined : true;
    }

    return requiredState[key] !== undefined;
  });
};

const consumeResult = <T,>(result: PromiseSettledResult<T>, onSuccess: (value: T) => void, errorMessage: string) => {
  // The error property comes from ApiV2 responses.
  // @ts-expect-error Property 'error' does not exist on type 'NonNullable<T>'
  if (result.status === "fulfilled" && !result.value?.error) {
    onSuccess(result.value);
  } else {
    console.log("Error fetching required data:", result);
    throw new Error(errorMessage, { cause: result });
  }
};

const filterAllowedEncoders = (encoders: Encoder.AsObject[]) => {
  const sortedEncoders = [];

  const boomerang = encoders?.find((encoder) => encoder.name === "boomerang-2023-q3");
  if (boomerang) sortedEncoders.push(boomerang);

  const vectaraLegacy = encoders?.find((encoder) => encoder.name === "vectara-legacy-v1");
  if (vectaraLegacy) sortedEncoders.push(vectaraLegacy);

  return sortedEncoders;
};

type RequiredState = {
  isOryAuth: boolean;
  defaultChatHistoryCorpusId: number | undefined;
  defaultChatHistoryCorpusKey: string | undefined;
  awsConfig: AwsConfig | undefined;
  isAwsMpCustomer: boolean | undefined;
  customer: Customer | undefined;
  enabledFeatures: EnabledFeatures | undefined;
  llms: Llm[] | undefined;
  generationPresets: GenerationPreset[] | undefined;
  userDetails: UserDetails | undefined;
  abilities: Abilities | undefined;
  pageAccess: PageAccess | undefined;
  rerankers: Reranker.AsObject[] | undefined;
  awsMpDetails: ReadAWSMPDetailsResponse.AsObject | undefined;
};

type LoginAttributes = Awaited<ReturnType<typeof fetchCustomerLoginAttribs>>;

interface UserContextType {
  hasInitiallyAuthenticated: boolean;
  resetInitialAuthentication: () => void;
  authenticate: () => Promise<void>;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  authenticationError?: AuthenticationError;
  deauthenticate: (queryString?: string, redirectPathOverride?: string) => Promise<void>;
  isDeauthenticating: boolean;
  acceptInvitation: () => void;
  customer?: Customer;
  enabledFeatures?: EnabledFeatures;
  generationPresets?: ApiV2["GenerationPreset"][];
  availableLlms: string[];
  llms: Llm[];
  llmToGenerationPresets: Record<string, GenerationPreset[]>;
  encoders?: Encoder.AsObject[];
  rerankers?: Reranker.AsObject[];
  userDetails?: UserDetails;
  abilities?: Abilities;
  pageAccess?: PageAccess;
  awsConfig?: AwsConfig;
  isOryAuth?: boolean;
  defaultChatHistoryCorpusId?: number;
  defaultChatHistoryCorpusKey?: string;
  isAwsMpCustomer?: boolean;
  awsMpDetails?: ReadAWSMPDetailsResponse.AsObject;
  analyticsAuthToken?: string;
  updateEnabledFeatures: () => void;
  getJwt: () => Promise<string>;
  getEncoderWithName: (name: string) => Encoder.AsObject | undefined;
  anonymousId: string;
  trialPlan?: TrialPlan;
}

type TrialPlan = {
  isExpired: boolean;
  expiryTimestamp: number;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

// This is a hack to get the context state outside of components (e.g. in the API middleware)
const globalValues = { customerId: "", getJwt: () => Promise.resolve("") };
export const getGlobalUserContextValues = () => globalValues;

type Props = {
  children: ReactNode;
};

export const UserContextProvider = ({ children }: Props) => {
  const { setUserTrackingData } = useAnalyticsContext();
  const [hasInitiallyAuthenticated, setHasInitiallyAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authenticationError, setAuthenticationError] = useState<AuthenticationError | undefined>(undefined);
  const [isDeauthenticating, setIsDeauthenticating] = useState(false);
  const [customer, setCustomer] = useState<Customer | undefined>(undefined);
  const [enabledFeatures, setEnabledFeatures] = useState<EnabledFeatures | undefined>(undefined);
  const [generationPresets, setGenerationPresets] = useState<ApiV2["GenerationPreset"][] | undefined>(undefined);
  const [availableLlms, setAvailableLlms] = useState<string[]>([]);
  const [llms, setLlms] = useState<Llm[]>([]);
  const [encoders, setEncoders] = useState<Encoder.AsObject[] | undefined>(undefined);
  const [rerankers, setRerankers] = useState<Reranker.AsObject[] | undefined>(undefined);
  const [userDetails, setUserDetails] = useState<UserDetails | undefined>(undefined);
  const [abilities, setAbilities] = useState<Abilities | undefined>(undefined);
  const [pageAccess, setPageAccess] = useState<PageAccess | undefined>(undefined);
  const [loginAttributes, setLoginAttributes] = useState<
    (OptionalFields<LoginAttributes, "awsConfig"> & { trialPlan?: TrialPlan }) | undefined
  >(undefined);
  const [awsMpDetails, setAwsMpDetails] = useState<ReadAWSMPDetailsResponse.AsObject | undefined>(undefined);
  const [analyticsAuthToken, setAnalyticsAuthToken] = useState<string | undefined>(undefined);

  const {
    isOryAuth = AUTH_PROVIDER === "ory",
    isAwsMpCustomer,
    trialPlan,
    defaultChatHistoryCorpusId,
    defaultChatHistoryCorpusKey,
    awsConfig
  } = loginAttributes ?? {};

  const [searchParams] = useSearchParams();
  const canHandleSessionExpiration = useRef(false);

  const getEncoderWithName = (name: string) => {
    return encoders?.find((encoder) => encoder.name === name);
  };

  const deauthenticate = useCallback(
    async (queryString: string | undefined = undefined, redirectPathOverride: string | undefined = undefined) => {
      try {
        setIsDeauthenticating(true);

        // Clear auth user data from local storage.
        removeAuthMechanism();

        setUserTrackingData(undefined); // Clear user tracking data
        analytics?.page({
          email: customer?.userEmail,
          customerId: customer?.customerId,
          userId: "",
          type: "LOGOUT",
          referrer: document.referrer
        });

        // If there's a customer, then the user has logged in.
        if (customer) {
          // If logged in via Google SSO, this will automatically redirect to /login.
          await logout(isOryAuth);
        }

        // This is unreachable for Google SSO users because of
        // the redirect above.
        let redirectPath = redirectPathOverride ?? "/login";

        if (queryString) {
          redirectPath += `?${queryString}`;
        }

        // Redirect to login. Use a hard reload to reset all app state.
        window.location.href = `${window.location.origin}${redirectPath}`;
      } catch (e) {
        setIsAuthenticating(false);
      }
    },
    [customer]
  );

  // For use in components that need to make authenticated requests.
  const getJwt = useCallback(async (): Promise<string> => {
    try {
      // This refreshes every 30 minutes so we can't cache it.
      const jwt = await refreshJwt(isOryAuth, AdminService);
      if (jwt) {
        return jwt;
      }
    } catch (e) {
      datadogRum.addError(e);
      if (canHandleSessionExpiration.current) {
        canHandleSessionExpiration.current = false;

        if (e === "No current user" && customer) {
          // Hacky solution to being bombarded by a thundering herd
          // of notifications if the JWT is expired but has been
          // requested by multiple call-sites simultaneously.
          ShowToaster("Session expired. Please login again.", "primary", 5000, IconNames.INFO_SIGN);
          const data = await db.collection("notifications").get();
          let id = data?.length > 0 ? data[data.length - 1]["id"] : 0;
          await db.collection("notifications").add({
            id: ++id,
            desc: "Session expired. Please login again.",
            title: "Session expired",
            time: formattedDate(),
            read: false,
            customerId: customer.customerId,
            userName: ""
          });
        }

        // If the JWT is expired, deauthenticate.
        const queryString = insertUserName(
          customer?.userName,
          insertCustomerId(customer?.customerId, searchParams)
        ).toString();

        await deauthenticate(queryString);
      }
      console.trace(e);
    }

    return ""; // Make TS happy
  }, [customer, deauthenticate]);

  const updateEnabledFeatures = useCallback(async () => {
    // When billing details change, we need to refresh the plan and enabled features.
    const jwt = await getJwt();

    if (customer) {
      // Fetch the enabled features.
      const { customerId } = customer;
      const enabledFeaturesResult = await fetchEnabledFeatures(jwt, AdminService, customerId);
      setEnabledFeatures(enabledFeaturesResult);
    }
  }, [getJwt, customer, setEnabledFeatures]);

  const resetInitialAuthentication = useCallback(() => {
    setHasInitiallyAuthenticated(false);
  }, [setHasInitiallyAuthenticated]);

  // This is called only when a user accepts an invitation. It enables the
  // user to skip authentication when they've been been invited to join an
  // account. It's very tightly coupled to that specific flow, which explains
  // the highly specific and non-generic name.
  const acceptInvitation = useCallback(() => {
    // Clear auth user data.
    removeAuthMechanism();

    // Clear user data external to auth.
    setLoginAttributes((state) => (state ? { ...state, awsConfig: undefined } : undefined));

    // Reset everything.
    setCustomer(undefined);
    setEnabledFeatures(undefined);
    setUserDetails(undefined);
    setAbilities(undefined);

    setIsAuthenticated(false);
    setIsAuthenticating(false);
    setHasInitiallyAuthenticated(true);
  }, [setIsAuthenticated, setIsAuthenticating, setHasInitiallyAuthenticated]);

  const authenticate = useCallback(async () => {
    const authMechanism = getAuthMechanism();

    setIsAuthenticated(false);
    setIsAuthenticating(true);

    let authenticationError: AuthenticationError | undefined;

    // This state is provided by amplify and other sources. We use it to access our APIs.
    let customerId: string | null = null;
    let jwt: string | undefined = undefined;
    let userHandle: string | undefined = undefined;
    // This is needed for setting user tracking data
    let userSub: string | undefined = undefined;

    // We're going to populate all of this state in order for the app to function. Don't put
    // anything here that isn't a requirement for the app to work.
    const requiredState: RequiredState = {
      isOryAuth: AUTH_PROVIDER === "ory",
      defaultChatHistoryCorpusId: undefined,
      defaultChatHistoryCorpusKey: undefined,
      awsConfig: undefined,
      isAwsMpCustomer: undefined,
      customer: undefined,
      enabledFeatures: undefined,
      llms: undefined,
      generationPresets: undefined,
      userDetails: undefined,
      abilities: undefined,
      pageAccess: undefined,
      rerankers: undefined,
      awsMpDetails: undefined
    };

    const storeCustomerLoginAttributes = (
      customerLoginAttributes: Awaited<ReturnType<typeof fetchCustomerLoginAttribs>>
    ) => {
      requiredState.isOryAuth = customerLoginAttributes.isOryAuth;
      requiredState.defaultChatHistoryCorpusId = customerLoginAttributes.defaultChatHistoryCorpusId;
      requiredState.defaultChatHistoryCorpusKey = customerLoginAttributes.defaultChatHistoryCorpusKey;
      requiredState.awsConfig = customerLoginAttributes.awsConfig;
      requiredState.isAwsMpCustomer = customerLoginAttributes.isAwsMpCustomer;

      const isOnTrialPlan = customerLoginAttributes.expiryTs !== null && customerLoginAttributes.expiryTs !== 0;
      setLoginAttributes({
        ...customerLoginAttributes,
        trialPlan: isOnTrialPlan
          ? {
              isExpired: customerLoginAttributes.isExpired,
              expiryTimestamp: customerLoginAttributes.expiryTs * 1000
            }
          : undefined
      });
    };

    try {
      switch (authMechanism) {
        case "googleSso": {
          // Retrieve jwt and user info from Amplify.
          try {
            await authenticateWithGoogle();
            jwt = await refreshJwt(requiredState.isOryAuth, AdminService);

            if (jwt) {
              // If we're registering, we need to create a new account on the backend.
              const isRegistration = getGoogleSsoAction() === "registering";

              const response = await LoginWithGoogleAuth(
                jwt,
                PublicAdminService,
                isRegistration,
                isRegistration ? getAndRemoveAwsSubscriptionToken() : undefined
              );
              console.log("Logged in with Google SSO");

              const { accountsList } = response;
              const account = accountsList.find(({ lastActive }) => lastActive);
              // This indicates a problem with the backend.
              if (!account) throw new Error("No active Google account found.");

              customerId = account.customerId.toFixed(0);

              // Init customer.
              userHandle = account.handle;
              requiredState.customer = {
                customerId,
                userName: userHandle,
                userEmail: userHandle
              };

              setCustomer(requiredState.customer);

              storeCustomerLoginAttributes(await fetchCustomerLoginAttribs(PublicAdminService, customerId));
            }
          } catch (e) {
            datadogRum.addError(e);
            if (e === "The user is not authenticated") {
              // This error comes from Auth.federatedSignIn. It occurs when
              // the user is logged into a Google account but hasn't used it
              // to SSO. Surfacing the error isn't helpful because the user
              // hasn't done anything wrong, so we swallow it.
            } else {
              throw e;
            }
          }

          break;
        }

        case "password": {
          // Get customer ID from localStorage.
          customerId = getCustomerId();

          if (customerId) {
            storeCustomerLoginAttributes(await fetchCustomerLoginAttribs(PublicAdminService, customerId));

            const user = await authenticateWithPassword(requiredState.isOryAuth, requiredState.awsConfig!);
            // Retrieve jwt and user from Amplify.
            jwt = await refreshJwt(requiredState.isOryAuth, AdminService);

            if (jwt && user) {
              const userEmail = requiredState.isOryAuth ? user.email : user.attributes.email;
              // Init customer.
              userHandle = user.username;
              requiredState.customer = {
                customerId,
                userName: userHandle,
                userEmail
              };

              setCustomer(requiredState.customer);
            }
          }

          break;
        }

        default: {
          break;
        }
      }

      if (customerId && jwt && userHandle) {
        const [
          enabledFeaturesResult,
          userDetailsResult,
          generationPresetsResult,
          llmsResult,
          encodersResult,
          rerankersResult,
          awsMpResult
        ] = await Promise.allSettled([
          fetchEnabledFeatures(jwt, AdminService, customerId),
          fetchUserDetails(jwt, AdminService, customerId, userHandle),
          fetchGenerationPresets(jwt, customerId),
          fetchLlms(jwt, customerId),
          fetchEncoders(jwt, AdminService, customerId),
          fetchRerankers(jwt, AdminService, customerId),
          fetchAwsMpDetails(jwt, AdminService, customerId, requiredState.isAwsMpCustomer!)
        ]);

        consumeResult(
          enabledFeaturesResult,
          (value) => {
            requiredState.enabledFeatures = value;
            setEnabledFeatures(value);
          },
          "Couldn't retrieve enabled features."
        );

        consumeResult(
          userDetailsResult,
          (value) => {
            const { analyticsAuthToken, userDetails, abilities, pageAccess } = value;
            requiredState.userDetails = userDetails;
            setUserDetails(userDetails);

            requiredState.abilities = abilities;
            setAbilities(abilities);

            requiredState.pageAccess = pageAccess;
            setPageAccess(pageAccess);

            userSub = requiredState.userDetails?.sub;

            setAnalyticsAuthToken(analyticsAuthToken);
          },
          "Couldn't retrieve user details."
        );

        consumeResult(
          generationPresetsResult,
          (value) => {
            requiredState.generationPresets = value.data?.generation_presets ?? [];
            setGenerationPresets(requiredState.generationPresets);
            setAvailableLlms(extractLlms(requiredState.generationPresets));
          },
          "Couldn't retrieve generation presets."
        );

        consumeResult(
          llmsResult,
          (value) => {
            requiredState.llms = value.data?.llms ?? [];
            setLlms(requiredState.llms);
          },
          "Couldn't retrieve LLMs."
        );

        consumeResult(
          encodersResult,
          (value) => {
            setEncoders(filterAllowedEncoders(value));
          },
          "Couldn't retrieve encoders."
        );

        consumeResult(
          rerankersResult,
          (value) => {
            requiredState.rerankers = value;
            setRerankers(value);
          },
          "Couldn't retrieve rerankers."
        );

        consumeResult(
          awsMpResult,
          (value) => {
            requiredState.awsMpDetails = value;
            setAwsMpDetails(value);
          },
          "Couldn't retrieve awsMpDetails."
        );
        // Set user tracking data
        const trackingData: UserTrackingData = {
          email: userHandle,
          customerId: parseInt(customerId),
          userSub: userSub || ""
        };
        setUserTrackingData(trackingData);
        analytics?.page({
          email: trackingData.email,
          customerId: trackingData.customerId,
          userId: trackingData.userSub,
          type: "LOGIN",
          referrer: document.referrer
        });
      }
    } catch (e: any) {
      datadogRum.addError(e);
      // TODO: Log to an external service like Datadog or Sentry.
      console.log("Authentication error", e);

      switch (e?.cause?.reason?.code) {
        case 16:
          if (requiredState.isAwsMpCustomer) {
            authenticationError = "expiredAwsSubscription";
          } else {
            authenticationError = "deactivatedAccount";
          }
          break;

        default:
          authenticationError = "genericLoginError";
      }
    }

    setIsAuthenticating(false);
    setHasInitiallyAuthenticated(true);

    // Depend on presence of required data to determine success state.
    // If this stuff is missing, then we'll show an error if one exists.
    // We don't depend on absence of an auth error, because if the user
    // isn't registered then auth fails but there aren't user-helpful errors.
    // NOTE: Plan is optional.
    if (isAllRequiredStateDefined(requiredState)) {
      setAuthenticationError(undefined);
      setIsAuthenticated(true);

      removeGoogleSsoAction();

      // If the session expires, we'll prompt the user to log in again.
      canHandleSessionExpiration.current = true;
    } else {
      setAuthenticationError(authenticationError);
    }
  }, [
    setIsAuthenticating,
    setHasInitiallyAuthenticated,
    setIsAuthenticated,
    setCustomer,
    setEnabledFeatures,
    setUserDetails
  ]);

  const llmToGenerationPresets =
    generationPresets?.reduce((acc, generationPreset) => {
      if (generationPreset.llm_name) {
        if (!acc[generationPreset.llm_name]) {
          acc[generationPreset.llm_name] = [];
        }
        acc[generationPreset.llm_name].push(generationPreset);
      }
      return acc;
    }, {} as Record<string, GenerationPreset[]>) ?? {};

  // always hold the latest customerId
  globalValues.customerId = customer?.customerId || "";
  globalValues.getJwt = getJwt;

  return (
    <UserContext.Provider
      value={{
        hasInitiallyAuthenticated,
        resetInitialAuthentication,
        authenticate,
        isAuthenticating,
        isAuthenticated,
        authenticationError,
        deauthenticate,
        isDeauthenticating,
        acceptInvitation,
        customer,
        enabledFeatures,
        generationPresets,
        availableLlms,
        llms,
        llmToGenerationPresets,
        encoders,
        rerankers,
        userDetails,
        abilities,
        pageAccess,
        awsConfig,
        isOryAuth,
        defaultChatHistoryCorpusId,
        defaultChatHistoryCorpusKey,
        isAwsMpCustomer,
        awsMpDetails,
        analyticsAuthToken,
        updateEnabledFeatures,
        getJwt,
        getEncoderWithName,
        anonymousId,
        trialPlan
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserContextProvider");
  }
  return context;
};
